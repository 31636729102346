import { createAsync, useLocation, useParams } from '@solidjs/router';
import { BreadcrumbItem, Breadcrumbs, Container, Heading, Picture } from '@troon/ui';
import { Meta, Title } from '@solidjs/meta';
import { ErrorBoundary, Show, Suspense } from 'solid-js';
import dayjs from '@troon/dayjs';
import { createContentfulRequest } from '../../../api/content/[model]/_client';
import { getConfigValue } from '../../../../modules/config';
import { NotFoundContent } from '../../../../partials/404';
import { RichText, richTextToPlain } from '../../../../components/rich-text';

export default function ArticlePreview() {
	const params = useParams<{ slug: string; type: string }>();
	const article = createAsync(async () => getArticle(params.slug, { type: 'Shot Scope Hub' }), { deferStream: true });
	const loc = useLocation();

	return (
		<ErrorBoundary fallback={<NotFoundContent />}>
			<Suspense>
				<Show when={article()?.fields}>
					{(article) => (
						<>
							<Title>{article().title} | Shot Scope | Troon</Title>
							<Meta name="description" content={richTextToPlain(article().summary)} />
							<Meta name="og:title" content={`${article().title} | Troon`} />
							<Meta name="og:url" content={`https://${getConfigValue('HOST')}/shot-scope/articles/${article().slug}`} />
							<Meta name="og:type" content="article" />
							<Meta name="og:image" content={`https://${article().heroImage?.fields.file?.url}`} />
						</>
					)}
				</Show>
			</Suspense>
			<Container class="pb-16 pt-4">
				<Suspense>
					<Show when={article()?.fields}>
						<Breadcrumbs>
							<BreadcrumbItem href="/shot-scope">Troon + Shot Scope</BreadcrumbItem>
							<BreadcrumbItem href={loc.pathname}>{article()?.fields.title}</BreadcrumbItem>
						</Breadcrumbs>
					</Show>
				</Suspense>
			</Container>
			<Container size="small" class="flex flex-col gap-8">
				<Heading as="h1">
					<Suspense>
						<Show when={article()?.fields}>{(data) => <>{data().title}</>}</Show>
					</Suspense>
				</Heading>
				<time class="text-lg">
					<Suspense>
						<Show when={article()?.sys}>
							{(data) => dayjs(data().createdAt, 'America/Phoenix').format('MMMM D, YYYY')}
						</Show>
					</Suspense>
				</time>
			</Container>
			<div class="container mx-auto my-8 max-w-screen-lg sm:px-6 md:px-12">
				<Suspense>
					<Show when={article()?.fields.heroImage}>
						{(image) => (
							<Picture
								preload
								src={image().fields.file?.url}
								sizes={[
									[480, 270],
									[1024, 576],
								]}
								class="w-full sm:rounded"
								alt={image().fields.description ?? image().fields.title ?? ''}
								crop="face"
							/>
						)}
					</Show>
				</Suspense>
			</div>
			<Suspense>
				<Show when={article()?.fields}>
					{(content) => (
						<Container size="small">
							<RichText document={content().content} />
						</Container>
					)}
				</Show>
			</Suspense>
		</ErrorBoundary>
	);
}

const getArticle = createContentfulRequest('articles');
